import { Root } from '_lib/Root';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth } from './Auth';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.Fragment>
    <Root>
      <Auth />
    </Root>
  </React.Fragment>,
);

reportWebVitals();
