import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  listBookingsCancelledEndCursor: string;
  listBookingsCancelledOrderBy: OrderBy;
  listBookingsCancelledPageNumber: number;
  listBookingsCancelledPageSize: number;
  listBookingsCancelledRowIndex: number;
  listBookingsCancelledSearchTerm: string;
}

const initialState: States = {
  listBookingsCancelledEndCursor: '',
  listBookingsCancelledOrderBy: {
    direction: Direction.Desc,
    field: 'startTime',
  },
  listBookingsCancelledPageNumber: 0,
  listBookingsCancelledPageSize: 100,
  listBookingsCancelledRowIndex: -1,
  listBookingsCancelledSearchTerm: '',
};

export const bookingsListBookingsCancelledSlice = createSlice({
  initialState,
  name: 'bookingsListBookingsCancelled',
  reducers: {
    setListBookingsCancelledEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsCancelledEndCursor = payload;
    },
    setListBookingsCancelledOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listBookingsCancelledOrderBy = payload;
    },
    setListBookingsCancelledPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsCancelledPageNumber = payload;
    },
    setListBookingsCancelledPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsCancelledPageSize = payload;
    },
    setListBookingsCancelledRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsCancelledRowIndex = payload;
    },
    setListBookingsCancelledSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsCancelledSearchTerm = payload;
    },
  },
});

export default bookingsListBookingsCancelledSlice.reducer;

export const {
  setListBookingsCancelledEndCursor: setListBookingsCancelledEndCursorAction,
  setListBookingsCancelledPageNumber: setListBookingsCancelledPageNumberAction,
  setListBookingsCancelledPageSize: setListBookingsCancelledPageSizeAction,
  setListBookingsCancelledRowIndex: setListBookingsCancelledRowIndexAction,
  setListBookingsCancelledSearchTerm: setListBookingsCancelledSearchTermAction,
} = bookingsListBookingsCancelledSlice.actions;
