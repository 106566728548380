import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface States {
  editTabIndex: number;
}

const initialState: States = {
  editTabIndex: 0,
};

export const pricingSlice = createSlice({
  initialState,
  name: 'pricing',
  reducers: {
    setEditTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.editTabIndex = payload;
    },
  },
});

export default pricingSlice.reducer;

export const { setEditTabIndex: setEditTabIndexAction } = pricingSlice.actions;
