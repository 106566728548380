import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const getDate = (date: string | undefined) => {
  if (date) {
    return new Date(date).toISOString();
  }

  return new Date().toISOString();
};

export interface States {
  listOrdersAttnEndCursor: string;
  listOrdersAttnEndDate: string | null;
  listOrdersAttnOrderBy: OrderBy;
  listOrdersAttnPageNumber: number;
  listOrdersAttnPageSize: number;
  listOrdersAttnRowIndex: number;
  listOrdersAttnSearchTerm: string;
  listOrdersAttnStartDate: string | null;
}

const initialState: States = {
  listOrdersAttnEndCursor: '',
  listOrdersAttnEndDate: getDate('2022-12-01'),
  listOrdersAttnOrderBy: {
    direction: Direction.Desc,
    field: 'date',
  },
  listOrdersAttnPageNumber: 0,
  listOrdersAttnPageSize: 10,
  listOrdersAttnRowIndex: -1,
  listOrdersAttnSearchTerm: '',
  listOrdersAttnStartDate: getDate('2022-09-01'),
};

export const reportListNonConnectedOrdersSlice = createSlice({
  initialState,
  name: 'reportListNonConnectedOrders',
  reducers: {
    setListOrdersAttnEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listOrdersAttnEndCursor = payload;
    },
    setListOrdersAttnOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listOrdersAttnOrderBy = payload;
    },
    setListOrdersAttnPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listOrdersAttnPageNumber = payload;
    },
    setListOrdersAttnPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listOrdersAttnPageSize = payload;
    },
    setListOrdersAttnRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listOrdersAttnRowIndex = payload;
    },
    setListOrdersAttnSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listOrdersAttnSearchTerm = payload;
    },
  },
});

export default reportListNonConnectedOrdersSlice.reducer;

export const {
  setListOrdersAttnEndCursor: setListOrdersAttnEndCursorAction,
  setListOrdersAttnPageNumber: setListOrdersAttnPageNumberAction,
  setListOrdersAttnPageSize: setListOrdersAttnPageSizeAction,
  setListOrdersAttnRowIndex: setListOrdersAttnRowIndexAction,
  setListOrdersAttnSearchTerm: setListOrdersAttnSearchTermAction,
} = reportListNonConnectedOrdersSlice.actions;
