import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface States {
  tabIndex: number;
}

const initialState: States = {
  tabIndex: 0,
};

export const coachesEditCoachSlice = createSlice({
  initialState,
  name: 'coachesEditCoach',
  reducers: {
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default coachesEditCoachSlice.reducer;

export const { setTabIndex: setTabIndexAction } = coachesEditCoachSlice.actions;
