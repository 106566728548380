import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const getDate = (date: string | undefined) => {
  if (date) {
    return new Date(date).toISOString();
  }

  return new Date().toISOString();
};

export interface States {
  listLessonVouchersEndCursor: string;
  listLessonVouchersEndDate: string | null;
  listLessonVouchersOrderBy: OrderBy;
  listLessonVouchersPageNumber: number;
  listLessonVouchersPageSize: number;
  listLessonVouchersRowIndex: number;
  listLessonVouchersSearchTerm: string;
  listLessonVouchersStartDate: string | null;
}

const initialState: States = {
  listLessonVouchersEndCursor: '',
  listLessonVouchersEndDate: getDate('2022-12-01'),
  listLessonVouchersOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listLessonVouchersPageNumber: 0,
  listLessonVouchersPageSize: 10,
  listLessonVouchersRowIndex: -1,
  listLessonVouchersSearchTerm: '',
  listLessonVouchersStartDate: getDate('2022-09-01'),
};

export const reportListLessonVouchersSlice = createSlice({
  initialState,
  name: 'reportListLessonVouchers',
  reducers: {
    setListLessonVouchersEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listLessonVouchersEndCursor = payload;
    },
    setListLessonVouchersOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listLessonVouchersOrderBy = payload;
    },
    setListLessonVouchersPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listLessonVouchersPageNumber = payload;
    },
    setListLessonVouchersPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listLessonVouchersPageSize = payload;
    },
    setListLessonVouchersRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listLessonVouchersRowIndex = payload;
    },
    setListLessonVouchersSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listLessonVouchersSearchTerm = payload;
    },
  },
});

export default reportListLessonVouchersSlice.reducer;

export const {
  setListLessonVouchersEndCursor: setListLessonVouchersEndCursorAction,
  setListLessonVouchersPageNumber: setListLessonVouchersPageNumberAction,
  setListLessonVouchersPageSize: setListLessonVouchersPageSizeAction,
  setListLessonVouchersRowIndex: setListLessonVouchersRowIndexAction,
  setListLessonVouchersSearchTerm: setListLessonVouchersSearchTermAction,
} = reportListLessonVouchersSlice.actions;
