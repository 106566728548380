import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  listAdminsEndCursor: string;
  listAdminsOrderBy: OrderBy;
  listAdminsPageNumber: number;
  listAdminsPageSize: number;
  listAdminsRowIndex: number;
  listAdminsSearchTerm: string;
}

const initialState: States = {
  listAdminsEndCursor: '',
  listAdminsOrderBy: {
    direction: Direction.Desc,
    field: 'lastAccessTokenDate',
  },
  listAdminsPageNumber: 0,
  listAdminsPageSize: 10,
  listAdminsRowIndex: -1,
  listAdminsSearchTerm: '',
};

export const adminsLisAdminsSlice = createSlice({
  initialState,
  name: 'adminsListAdmins',
  reducers: {
    setListAdminsEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listAdminsEndCursor = payload;
    },
    setListAdminsOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listAdminsOrderBy = payload;
    },
    setListAdminsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listAdminsPageNumber = payload;
    },
    setListAdminsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listAdminsPageSize = payload;
    },
    setListAdminsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listAdminsRowIndex = payload;
    },
    setListAdminsSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.listAdminsSearchTerm = payload;
    },
  },
});

export default adminsLisAdminsSlice.reducer;

export const {
  setListAdminsEndCursor: setListAdminsEndCursorAction,
  setListAdminsPageNumber: setListAdminsPageNumberAction,
  setListAdminsPageSize: setListAdminsPageSizeAction,
  setListAdminsRowIndex: setListAdminsRowIndexAction,
  setListAdminsSearchTerm: setListAdminsSearchTermAction,
} = adminsLisAdminsSlice.actions;
