import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import tw from 'twin.macro';

interface CardProps {
  Section?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  isDisabled?: boolean;
}

const CardContainer = styled.div`
  ${tw`box-border relative w-full mx-auto bg-white border-0 border-solid rounded-lg border-brd-neutral-subdued-light shadow-card text-t-base-light dark:bg-white`};
`;

const Overlay = styled.div`
  ${tw`absolute inset-0 bg-white cursor-not-allowed opacity-60 dark:bg-white`};
`;

const FooterContainer = styled.div`
  ${tw`relative w-full p-5`};
`;

export const Card = (props: CardProps) => {
  const { children, footer, isDisabled = false } = props;

  return (
    <CardContainer>
      {children}
      {isDisabled && <Overlay />}
      {footer && <FooterContainer>{footer}</FooterContainer>}
    </CardContainer>
  );
};

export * from './Section';
export * from './CardHeading';
