import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface States {
  tabIndex: number;
}

const initialState: States = {
  tabIndex: 0,
};

export const reportEditOrderSlice = createSlice({
  initialState,
  name: 'reportEditOrder',
  reducers: {
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default reportEditOrderSlice.reducer;

export const { setTabIndex: setTabIndexAction } = reportEditOrderSlice.actions;
