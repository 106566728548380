import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface States {
  editModalIsOpen: boolean;
  kGolfShopGuid: string;
  tabIndex: number;
}

const initialState: States = {
  editModalIsOpen: false,
  kGolfShopGuid: '',
  tabIndex: 0,
};

export const clubhousesEditClubhouseSlice = createSlice({
  initialState,
  name: 'clubhousesEditClubhouse',
  reducers: {
    setEditModalIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.editModalIsOpen = payload;
    },
    setKGolfShopGuid: (state, { payload }: PayloadAction<string>) => {
      state.kGolfShopGuid = payload;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default clubhousesEditClubhouseSlice.reducer;

export const {
  setEditModalIsOpen: setEditModalIsOpenAction,
  setKGolfShopGuid: setKGolfShopGuidAction,
  setTabIndex: setTabIndexAction,
} = clubhousesEditClubhouseSlice.actions;
