import { AdminReportMemberStatType, Direction } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  memberStatsEndCursor: string;
  memberStatsPageNumber: number;
  memberStatsPageSize: number;
  memberStatsRowIndex: number;
  memberStatsSearchTerm: string;
  memberStatsSortOrder: Direction;
  memberStatsStatType: AdminReportMemberStatType;
}

const initialState: States = {
  memberStatsEndCursor: '',
  memberStatsPageNumber: 0,
  memberStatsPageSize: 10,
  memberStatsRowIndex: -1,
  memberStatsSearchTerm: '',
  memberStatsSortOrder: Direction.Desc,
  memberStatsStatType: AdminReportMemberStatType.CreditTransactionCount,
};

export const reportMemberStatsSlice = createSlice({
  initialState,
  name: 'reportMemberStats',
  reducers: {
    setMemberStatsEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.memberStatsEndCursor = payload;
    },
    setMemberStatsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.memberStatsPageNumber = payload;
    },
    setMemberStatsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.memberStatsPageSize = payload;
    },
    setMemberStatsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.memberStatsRowIndex = payload;
    },
    setMemberStatsSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.memberStatsSearchTerm = payload;
    },
    setMemberStatsSortOrder: (state, { payload }: PayloadAction<Direction>) => {
      state.memberStatsSortOrder = payload;
    },
    setMemberStatsStatType: (
      state,
      { payload }: PayloadAction<AdminReportMemberStatType>,
    ) => {
      state.memberStatsStatType = payload;
    },
  },
});

export default reportMemberStatsSlice.reducer;

export const {
  setMemberStatsEndCursor: setMemberStatsEndCursorAction,
  setMemberStatsPageNumber: setMemberStatsPageNumberAction,
  setMemberStatsPageSize: setMemberStatsPageSizeAction,
  setMemberStatsRowIndex: setMemberStatsRowIndexAction,
  setMemberStatsSearchTerm: setMemberStatsSearchTermAction,
  setMemberStatsSortOrder: setMemberStatsSortOrderAction,
  setMemberStatsStatType: setMemberStatsStatTypeAction,
} = reportMemberStatsSlice.actions;
