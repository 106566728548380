import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  listMembersEndCursor: string;
  listMembersOrderBy: OrderBy;
  listMembersPageNumber: number;
  listMembersPageSize: number;
  listMembersRowIndex: number;
  listMembersSearchTerm: string;
}

const initialState: States = {
  listMembersEndCursor: '',
  listMembersOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listMembersPageNumber: 0,
  listMembersPageSize: 100,
  listMembersRowIndex: -1,
  listMembersSearchTerm: '',
};

export const membersListMembersSlice = createSlice({
  initialState,
  name: 'membersLisMembers',
  reducers: {
    setListMembersEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listMembersEndCursor = payload;
    },
    setListMembersOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listMembersOrderBy = payload;
    },
    setListMembersPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listMembersPageNumber = payload;
    },
    setListMembersPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listMembersPageSize = payload;
    },
    setListMembersRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listMembersRowIndex = payload;
    },
    setListMembersSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.listMembersSearchTerm = payload;
    },
  },
});

export default membersListMembersSlice.reducer;

export const {
  setListMembersEndCursor: setListMembersEndCursorAction,
  setListMembersPageNumber: setListMembersPageNumberAction,
  setListMembersPageSize: setListMembersPageSizeAction,
  setListMembersRowIndex: setListMembersRowIndexAction,
  setListMembersSearchTerm: setListMembersSearchTermAction,
} = membersListMembersSlice.actions;
