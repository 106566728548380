//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/button.ts

import { StyleConfig } from '_types';

export const Button: StyleConfig = {
  baseStyle: (props) => {
    const { _colorMode, _colorScheme } = props;

    return {
      _disabled: {
        boxShadow: 'none',
        cursor: 'not-allowed',
        opacity: 0.4,
      },
      _focus: {
        boxShadow: 'outline',
      },
      _hover: {
        _disabled: {
          bg: 'initial',
        },
      },

      fontFamily: `-apple-system,"system-ui","San Francisco","Segoe UI",Roboto,"Helvetica Neue"`,
      transitionDuration: 'normal',
      transitionProperty: 'common',
    };
  },

  sizes: {},

  variants: {
    ghost: (props) => {
      const { _colorScheme, colorMode } = props;

      let bg = 'transparent';
      let bgHovered = '';
      let color = '';

      if (colorMode === 'dark') {
        bg = '#e8e8e8';
        bgHovered = '#dddddd';
        color = '#000000';
      }

      if (colorMode === 'light') {
        bg = '#e8e8e8';
        bgHovered = '#dddddd';
        color = '#000000';
      }

      return {
        _focus: {
          boxShadow: 'none',
          outlineColor: 'theme-primary-b-dark',
          outlineOffset: '4px',
          outlineWidth: '1px',
        },
        _hover: {
          _disabled: {
            bg,
          },
          bg: bgHovered,
        },
        bg,
        borderRadius: '0.25rem',
        color,
        fontSize: 'sm',
        fontWeight: '400',
        height: '36px',
        lineHeight: '1',
      };
    },

    icon: (props) => {
      const { colorMode, colorScheme } = props;

      let bg = '';
      let bgHovered = '';
      let color = '';
      let outlineColor = 'theme-primary-b-dark';
      let borderColor = '';

      if (colorScheme === 'primary-action' && colorMode === 'dark') {
        bg = 'theme-primary-b-dark';
        borderColor = 'theme-primary-b-dark';
        bgHovered = 'theme-primary-s-dark-hovered';
        color = '#ffffff';
      }

      if (colorScheme === 'primary-action' && colorMode === 'light') {
        bg = 'theme-primary-b-dark';
        borderColor = 'theme-primary-b-dark';
        color = '#ffffff';
        bgHovered = 'theme-primary-s-dark-hovered';
      }

      if (colorScheme === 'secondary-action' && colorMode === 'dark') {
        borderColor = '#5967C3';
        bg = '#5967C3';
        color = '#ffffff';
        bgHovered = '#4556c1';
      }

      if (colorScheme === 'secondary-action' && colorMode === 'light') {
        borderColor = '#5967C3';
        bg = '#5967C3';
        color = '#ffffff';
        bgHovered = '#4556c1';
      }

      if (colorScheme === 'tertiary-action' && colorMode === 'dark') {
        borderColor = 's-icon-neutral';
        bg = 's-icon-neutral';
        color = '#ffffff';
        bgHovered = 's-icon-neutral-hovered';
      }

      if (colorScheme === 'tertiary-action' && colorMode === 'light') {
        borderColor = 's-icon-neutral';
        bg = 's-icon-neutral';
        color = '#ffffff';
        bgHovered = 's-icon-neutral-hovered';
      }

      if (colorScheme === 'destructive' && colorMode === 'dark') {
        borderColor = 's-danger';
        bg = 's-danger';
        color = '#ffffff';
        bgHovered = 's-danger-hovered';
        outlineColor = 'brd-critical';
      }

      if (colorScheme === 'destructive' && colorMode === 'light') {
        borderColor = 's-danger';
        bg = 's-danger';
        color = '#ffffff';
        bgHovered = 's-danger-hovered';
        outlineColor = 'brd-critical';
      }

      return {
        _focus: {
          boxShadow: 'none',
          outlineColor: 'transparent',
          outlineStyle: 'dashed',
          outlineWidth: '1px',
        },
        bg,
        borderColor,
        color,
        height: '28px',
        svg: {
          height: '20px',
          width: '20px',
        },
        width: '32px',
      };
    },

    link: (props) => {
      const { color, colorMode } = props;

      return {
        _focus: {
          boxShadow: 'none',
          outlineColor: 'theme-primary-b-dark',
          outlineOffset: '0.5rem',
          outlineStyle: 'dashed',
          outlineWidth: '1px',
        },
        color: colorMode === 'dark' ? '#000000' : '#000000',
        h: 4,
        padding: '0',
        textDecoration: 'underline',
      };
    },

    outline: (props) => {
      const { colorMode, colorScheme } = props;

      const bg = 'transparent';
      const bgHovered = 'transparent';
      let color = '';
      let colorHovered = '';
      let borderColor = '';
      let borderColorHovered = '';
      let outlineColor = 'theme-primary-b-dark';

      if (colorMode === 'dark') {
        color = 't-subdued-dark';
        colorHovered = '#ffffff';
        borderColor = 'brd-neutral-subdued-dark';
        borderColorHovered = '#ffffff';
      }

      if (colorMode === 'light') {
        color = '#000000';
        colorHovered = '#000000';
        borderColor = '#000000';
        borderColorHovered = '#000000';
      }

      if (colorScheme === 'destructive') {
        color = 's-danger';
        colorHovered = 's-danger';
        borderColor = 's-danger';
        borderColorHovered = 's-danger';
        outlineColor = 'brd-critical';
      }

      return {
        _focus: {
          boxShadow: 'none',
          outlineColor,
          outlineOffset: '4px',
          outlineWidth: '1px',
        },
        _hover: {
          _disabled: {
            bg,
            borderColor,
            color,
          },
          bg: bgHovered,
          borderColor: borderColorHovered,
          color: colorHovered,
        },
        bg,
        borderColor,
        borderRadius: '0.25rem',
        borderWidth: '2px',
        color,
        fontSize: 'sm',
        fontWeight: '400',
        height: '36px',
        lineHeight: '1',
      };
    },

    outlineSm: (props) => {
      const { colorMode, colorScheme } = props;

      const bg = 'transparent';
      const bgHovered = 'transparent';
      let color = '';
      let colorHovered = '';
      let borderColor = '';
      let borderColorHovered = '';
      let outlineColor = 'theme-primary-b-dark';

      if (colorMode === 'dark') {
        color = 't-subdued-dark';
        colorHovered = '#ffffff';
        borderColor = 'brd-neutral-subdued-dark';
        borderColorHovered = '#ffffff';
      }

      if (colorMode === 'light') {
        color = '#000000';
        colorHovered = '#000000';
        borderColor = '#000000';
        borderColorHovered = '#000000';
      }

      if (colorScheme === 'destructive') {
        color = 's-danger';
        colorHovered = 's-danger';
        borderColor = 's-danger';
        borderColorHovered = 's-danger';
        outlineColor = 'brd-critical';
      }

      return {
        _focus: {
          boxShadow: 'none',
          outlineColor,
          outlineOffset: '4px',
          outlineWidth: '1px',
        },
        _hover: {
          _disabled: {
            bg,
            borderColor,
            color,
          },
          bg: bgHovered,
          borderColor: borderColorHovered,
          color: colorHovered,
        },
        bg,
        borderColor,
        borderRadius: '0.25rem',
        borderWidth: '1px',
        color,
        fontSize: 'xs',
        fontWeight: '400',
        height: '28px',
        lineHeight: '1',
      };
    },

    solid: (props) => {
      const { colorMode, colorScheme } = props;

      let bg = '';
      let bgHovered = '';
      let color = '';
      let outlineColor = 'theme-primary-b-dark';
      let borderColor = '';

      if (colorScheme === 'primary-action' && colorMode === 'dark') {
        bg = 'theme-primary-b-dark';
        borderColor = 'theme-primary-b-dark';
        bgHovered = 'theme-primary-s-dark-hovered';
        color = '#ffffff';
      }

      if (colorScheme === 'primary-action' && colorMode === 'light') {
        bg = 'theme-primary-b-dark';
        borderColor = 'theme-primary-b-dark';
        color = '#ffffff';
        bgHovered = 'theme-primary-s-dark-hovered';
      }

      if (colorScheme === 'secondary-action' && colorMode === 'dark') {
        borderColor = '#5967C3';
        bg = '#5967C3';
        color = '#ffffff';
        bgHovered = '#4556c1';
      }

      if (colorScheme === 'secondary-action' && colorMode === 'light') {
        borderColor = '#5967C3';
        bg = '#5967C3';
        color = '#ffffff';
        bgHovered = '#4556c1';
      }

      if (colorScheme === 'tertiary-action' && colorMode === 'dark') {
        borderColor = 's-icon-neutral';
        bg = 's-icon-neutral';
        color = '#ffffff';
        bgHovered = 's-icon-neutral-hovered';
      }

      if (colorScheme === 'tertiary-action' && colorMode === 'light') {
        borderColor = 's-icon-neutral';
        bg = 's-icon-neutral';
        color = '#ffffff';
        bgHovered = 's-icon-neutral-hovered';
      }

      if (colorScheme === 'destructive' && colorMode === 'dark') {
        borderColor = 's-danger';
        bg = 's-danger';
        color = '#ffffff';
        bgHovered = 's-danger-hovered';
        outlineColor = 'brd-critical';
      }

      if (colorScheme === 'destructive' && colorMode === 'light') {
        borderColor = 's-danger';
        bg = 's-danger';
        color = '#ffffff';
        bgHovered = 's-danger-hovered';
        outlineColor = 'brd-critical';
      }

      return {
        _focus: {
          boxShadow: 'none',
          outlineColor,
          outlineOffset: '4px',
          outlineWidth: '1px',
        },
        _hover: {
          _disabled: {
            bg,
            borderColor,
          },
          bg: bgHovered,
          borderColor,
        },
        bg,
        borderColor,
        borderRadius: '0.25rem',
        borderWidth: '2px',
        color,
        fontSize: 'sm',
        fontWeight: '400',
        height: '36px',
        lineHeight: '1',
      };
    },

    solidSm: (props) => {
      const { colorMode, colorScheme } = props;

      let bg = '';
      let bgHovered = '';
      let color = '';
      let outlineColor = 'theme-primary-b-dark';
      let borderColor = '';

      if (colorScheme === 'primary-action' && colorMode === 'dark') {
        bg = 'theme-primary-b-dark';
        borderColor = 'theme-primary-b-dark';
        bgHovered = 'theme-primary-s-dark-hovered';
        color = '#ffffff';
      }

      if (colorScheme === 'primary-action' && colorMode === 'light') {
        bg = 'theme-primary-b-dark';
        borderColor = 'theme-primary-b-dark';
        color = '#ffffff';
        bgHovered = 'theme-primary-s-dark-hovered';
      }

      if (colorScheme === 'secondary-action' && colorMode === 'dark') {
        borderColor = '#5967C3';
        bg = '#5967C3';
        color = '#ffffff';
        bgHovered = '#4556c1';
      }

      if (colorScheme === 'secondary-action' && colorMode === 'light') {
        borderColor = '#5967C3';
        bg = '#5967C3';
        color = '#ffffff';
        bgHovered = '#4556c1';
      }

      if (colorScheme === 'tertiary-action' && colorMode === 'dark') {
        borderColor = 's-icon-neutral';
        bg = 's-icon-neutral';
        color = '#ffffff';
        bgHovered = 's-icon-neutral-hovered';
      }

      if (colorScheme === 'tertiary-action' && colorMode === 'light') {
        borderColor = 's-icon-neutral';
        bg = 's-icon-neutral';
        color = '#ffffff';
        bgHovered = 's-icon-neutral-hovered';
      }

      if (colorScheme === 'destructive' && colorMode === 'dark') {
        borderColor = 's-danger';
        bg = 's-danger';
        color = '#ffffff';
        bgHovered = 's-danger-hovered';
        outlineColor = 'brd-critical';
      }

      if (colorScheme === 'destructive' && colorMode === 'light') {
        borderColor = 's-danger';
        bg = 's-danger';
        color = '#ffffff';
        bgHovered = 's-danger-hovered';
        outlineColor = 'brd-critical';
      }

      return {
        _focus: {
          boxShadow: 'none',
          outlineColor,
          outlineOffset: '4px',
          outlineWidth: '1px',
        },
        _hover: {
          _disabled: {
            bg,
            borderColor,
          },
          bg: bgHovered,
          borderColor,
        },
        bg,
        borderColor,
        borderRadius: '0.25rem',
        borderWidth: '1px',
        color,
        fontSize: 'xs',
        fontWeight: '400',
        height: '28px',
        lineHeight: '1',
      };
    },
  },
};
