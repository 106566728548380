import { NotificationMode } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SelectedMember = {
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
};

interface States {
  mode: NotificationMode;
  selectedMember: SelectedMember;
}

const initialState: States = {
  mode: NotificationMode.Broadcast,
  selectedMember: {
    memberIndividualId: '',
    memberName: '',
    memberUuid: '',
  },
};

export const notificationsNewNotificationSlice = createSlice({
  initialState,
  name: 'notificationsNewNotification',
  reducers: {
    setMode: (state, { payload }: PayloadAction<NotificationMode>) => {
      state.mode = payload;
    },
    setSelectedMember: (state, { payload }: PayloadAction<SelectedMember>) => {
      state.selectedMember = payload;
    },
  },
});

export default notificationsNewNotificationSlice.reducer;

export const {
  setMode: setModeAction,
  setSelectedMember: setSelectedMemberAction,
} = notificationsNewNotificationSlice.actions;
