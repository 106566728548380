import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface States {
  editModalIsOpen: boolean;
  tabIndex: number;
}

const initialState: States = {
  editModalIsOpen: false,
  tabIndex: 0,
};

export const membershipTypesEditMembershipTypeSlice = createSlice({
  initialState,
  name: 'membershipTypesEditMembershipType',
  reducers: {
    setEditModalIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.editModalIsOpen = payload;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default membershipTypesEditMembershipTypeSlice.reducer;

export const {
  setEditModalIsOpen: setEditModalIsOpenAction,
  setTabIndex: setTabIndexAction,
} = membershipTypesEditMembershipTypeSlice.actions;
