import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  listNotificationsEndCursor: string;
  listNotificationsEndDate: string | null;
  listNotificationsOrderBy: OrderBy;
  listNotificationsPageNumber: number;
  listNotificationsPageSize: number;
  listNotificationsRowIndex: number;
  listNotificationsSearchTerm: string;
  listNotificationsStartDate: string | null;
}

const initialState: States = {
  listNotificationsEndCursor: '',
  listNotificationsEndDate: null,
  listNotificationsOrderBy: {
    direction: Direction.Desc,
    field: 'createdAt',
  },
  listNotificationsPageNumber: 0,
  listNotificationsPageSize: 100,
  listNotificationsRowIndex: -1,
  listNotificationsSearchTerm: '',
  listNotificationsStartDate: null,
};

export const notificationsListNotificationsSlice = createSlice({
  initialState,
  name: 'notificationsListNotifications',
  reducers: {
    setListNotificationsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listNotificationsEndCursor = payload;
    },
    setListNotificationsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listNotificationsOrderBy = payload;
    },
    setListNotificationsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsPageNumber = payload;
    },
    setListNotificationsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsPageSize = payload;
    },
    setListNotificationsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listNotificationsRowIndex = payload;
    },
    setListNotificationsSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listNotificationsSearchTerm = payload;
    },
  },
});

export default notificationsListNotificationsSlice.reducer;

export const {
  setListNotificationsEndCursor: setListNotificationsEndCursorAction,
  setListNotificationsPageNumber: setListNotificationsPageNumberAction,
  setListNotificationsPageSize: setListNotificationsPageSizeAction,
  setListNotificationsRowIndex: setListNotificationsRowIndexAction,
  setListNotificationsSearchTerm: setListNotificationsSearchTermAction,
} = notificationsListNotificationsSlice.actions;
