import { Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { AlertModal } from 'components/AlertModal';
import afternoon from 'images/splash-afternoon.jpg';
import evening from 'images/splash-evening.jpg';
import morning from 'images/splash-morning.jpg';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import tw from 'twin.macro';

interface LayoutProps {
  children: ReactNode;
}

const Container = styled.div`
  ${tw`flex items-center justify-center h-screen overflow-hidden bg-s-neutral-subdued-light lg:justify-start lg:px-28`};
  background-image: url(afternoon);
`;

const ImageContainer = styled.div`
  ${tw`absolute inset-0`};
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

function Component(props: LayoutProps) {
  const { children } = props;

  const selector = useSelector((state: RootState) => state.ui.greeting);

  return (
    <React.Fragment>
      <Container>
        <ImageContainer>
          <img alt="afternoon" src={afternoon} />
        </ImageContainer>
        {children}
      </Container>
      <AlertModal />
    </React.Fragment>
  );
}

export const Layout = Component;
