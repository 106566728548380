import { configureStore } from '@reduxjs/toolkit';
import { adminsEditAdminSlice } from 'features/Admins/EditAdmin/store';
import { adminsLisAdminsSlice } from 'features/Admins/ListAdmins/store';
import { bookingsEditBookingSlice } from 'features/Bookings/EditBooking/store';
import { bookingsListBookingsCancelledSlice } from 'features/Bookings/ListBookingsCancelled/store';
import { bookingsListBookingsCompletedSlice } from 'features/Bookings/ListBookingsCompleted/store';
import { bookingsListBookingsTodaySlice } from 'features/Bookings/ListBookingsToday/store';
import { bookingsListBookingsUpcomingSlice } from 'features/Bookings/ListBookingsUpcoming/store';
import { bookingsNewBookingSlice } from 'features/Bookings/NewBooking/store';
import { clubhousesEditClubhouseSlice } from 'features/Clubhouses/EditClubhouse/store';
import { clubhousesListClubhousesSlice } from 'features/Clubhouses/ListClubhouses/store';
import { clubhouseStaffEditClubhouseStaffSlice } from 'features/ClubhouseStaff/EditClubhouseStaff/store';
import { clubhouseStaffListClubhouseStaffSlice } from 'features/ClubhouseStaff/ListClubhouseStaff/store';
import { coachesEditCoachSlice } from 'features/Coaches/EditCoach/store';
import { coachesListCoachesSlice } from 'features/Coaches/ListCoaches/store';
import { developerSlice } from 'features/Developer/store';
import { membersEditMemberSlice } from 'features/Members/EditMember/store';
import { membersEditSubscriptionsSlice } from 'features/Members/EditSubscriptions/store';
import { membersListMembersSlice } from 'features/Members/ListMembers/store';
import { membersListSubscriptionsSlice } from 'features/Members/ListSubscriptions/store';
import { membershipTypesEditMembershipTypeSlice } from 'features/MembershipTypes/EditMembershipType/store';
import { notificationsEditNotificationSlice } from 'features/Notifications/EditNotification/store';
import { notificationsListNotificationsSlice } from 'features/Notifications/ListNotifications/store';
import { notificationsNewNotificationSlice } from 'features/Notifications/NewNotification/store';
import { pricingSlice } from 'features/Pricing/store';
import { dataExportSlice } from 'features/Report/DataExport/store';
import { reportEditOrderSlice } from 'features/Report/EditOrder/store';
import { reportListCreditTransactionsSlice } from 'features/Report/ListCreditTransactions/store';
import { reportListLessonVouchersSlice } from 'features/Report/ListLessonVouchers/store';
import { reportListNonConnectedOrdersSlice } from 'features/Report/ListNonConnectedOrders/store';
import { reportListOrdersSlice } from 'features/Report/ListOrders/store';
import { reportListSubscriptionsSlice } from 'features/Report/ListSubscriptions/store';
import { reportMemberStatsSlice } from 'features/Report/MemberStats/store';
import { settingsSlice } from 'features/Settings/EditProfile/store';
import { shopSlice } from 'features/Shop/store';
import { useDispatch } from 'react-redux';
import { authSlice } from './auth';
import { uiSlice } from './ui';

export const store = configureStore({
  devTools: true,
  reducer: {
    adminsEditAdmin: adminsEditAdminSlice.reducer,
    adminsListAdmins: adminsLisAdminsSlice.reducer,
    auth: authSlice.reducer,
    bookingsEditBooking: bookingsEditBookingSlice.reducer,
    bookingsListBookingsCancelled: bookingsListBookingsCancelledSlice.reducer,
    bookingsListBookingsCompleted: bookingsListBookingsCompletedSlice.reducer,
    bookingsListBookingsToday: bookingsListBookingsTodaySlice.reducer,
    bookingsListBookingsUpcoming: bookingsListBookingsUpcomingSlice.reducer,
    bookingsNewBooking: bookingsNewBookingSlice.reducer,
    clubhouseStaffEditClubhouseStaff:
      clubhouseStaffEditClubhouseStaffSlice.reducer,
    clubhouseStaffListClubhouseStaff:
      clubhouseStaffListClubhouseStaffSlice.reducer,
    clubhousesEditClubhouse: clubhousesEditClubhouseSlice.reducer,
    clubhousesListClubhouses: clubhousesListClubhousesSlice.reducer,
    coachesEditCoach: coachesEditCoachSlice.reducer,
    coachesListCoaches: coachesListCoachesSlice.reducer,
    dataExport: dataExportSlice.reducer,
    developer: developerSlice.reducer,
    membersEditMember: membersEditMemberSlice.reducer,
    membersEditSubscriptions: membersEditSubscriptionsSlice.reducer,
    membersListMembers: membersListMembersSlice.reducer,
    membersListSubscriptions: membersListSubscriptionsSlice.reducer,
    membershipTypesEditMembershipType:
      membershipTypesEditMembershipTypeSlice.reducer,
    notificationsEditNotification: notificationsEditNotificationSlice.reducer,
    notificationsListNotifications: notificationsListNotificationsSlice.reducer,
    notificationsNewNotification: notificationsNewNotificationSlice.reducer,
    pricing: pricingSlice.reducer,
    reportEditOrder: reportEditOrderSlice.reducer,
    reportListCreditTransactions: reportListCreditTransactionsSlice.reducer,
    reportListLessonVouchers: reportListLessonVouchersSlice.reducer,
    reportListNonConnectedOrders: reportListNonConnectedOrdersSlice.reducer,
    reportListOrders: reportListOrdersSlice.reducer,
    reportListSubscriptions: reportListSubscriptionsSlice.reducer,
    reportMemberStats: reportMemberStatsSlice.reducer,
    settings: settingsSlice.reducer,
    shop: shopSlice.reducer,
    ui: uiSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch();
