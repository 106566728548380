import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const getDate = (date: string | undefined) => {
  if (date) {
    return new Date(date).toISOString();
  }

  return new Date().toISOString();
};

export interface States {
  listSubscriptionsEndCursor: string;
  listSubscriptionsEndDate: string | null;
  listSubscriptionsOrderBy: OrderBy;
  listSubscriptionsPageNumber: number;
  listSubscriptionsPageSize: number;
  listSubscriptionsRowIndex: number;
  listSubscriptionsSearchTerm: string;
  listSubscriptionsStartDate: string | null;
}

const initialState: States = {
  listSubscriptionsEndCursor: '',
  listSubscriptionsEndDate: getDate('2022-12-01'),
  listSubscriptionsOrderBy: {
    direction: Direction.Desc,
    field: 'startDate',
  },
  listSubscriptionsPageNumber: 0,
  listSubscriptionsPageSize: 10,
  listSubscriptionsRowIndex: -1,
  listSubscriptionsSearchTerm: '',
  listSubscriptionsStartDate: getDate('2022-09-01'),
};

export const reportListSubscriptionsSlice = createSlice({
  initialState,
  name: 'reportListSubscriptions',
  reducers: {
    setListSubscriptionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listSubscriptionsEndCursor = payload;
    },
    setListSubscriptionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listSubscriptionsOrderBy = payload;
    },
    setListSubscriptionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsPageNumber = payload;
    },
    setListSubscriptionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsPageSize = payload;
    },
    setListSubscriptionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listSubscriptionsRowIndex = payload;
    },
    setListSubscriptionsSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listSubscriptionsSearchTerm = payload;
    },
  },
});

export default reportListSubscriptionsSlice.reducer;

export const {
  setListSubscriptionsEndCursor: setListSubscriptionsEndCursorAction,
  setListSubscriptionsPageNumber: setListSubscriptionsPageNumberAction,
  setListSubscriptionsPageSize: setListSubscriptionsPageSizeAction,
  setListSubscriptionsRowIndex: setListSubscriptionsRowIndexAction,
  setListSubscriptionsSearchTerm: setListSubscriptionsSearchTermAction,
} = reportListSubscriptionsSlice.actions;
