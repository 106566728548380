export const colors = {
  'brand-brown': 'rgba(88, 55, 38, 1)',
  'brand-brown-hovered': 'rgba(91, 71, 61, 1)',

  'brd-critical': 'rgba(253, 87, 73, 1)',
  'brd-neutral-subdued': 'red',
  'brd-neutral-subdued-dark': '#ededed',
  'brd-neutral-subdued-light': 'rgba(186, 191, 195, 1)',
  'brd-success': 'rgba(174, 233, 209, 1)',

  divider: 'rgba(225, 227, 229, 1)',

  's-critical': 'rgba(254, 211, 209, 1)',
  's-critical-disabled': 'rgba(255, 244, 244, 1)',
  's-critical-subdued': 'rgba(255, 244, 244, 1)',
  's-critical-subdued-hovered': 'rgba(255, 240, 240, 1)',
  's-critical-subdued-pressed': 'rgba(255, 233, 232, 1)',

  's-danger': 'rgba(220, 53, 69, 1)',
  's-danger-hovered': 'rgba(198, 33, 47, 1)',

  's-highlight': 'rgba(255, 215, 157, 1)',
  's-highlight-disabled': 'rgba(255, 245, 234, 1)',
  's-highlight-subdued': 'rgba(255, 245, 234, 1)',
  's-highlight-subdued-hovered': 'rgba(255, 242, 226, 1)',
  's-highlight-subdued-pressed': 'rgba(255, 235, 211, 1)',

  's-icon-neutral': 'rgba(92, 95, 98, 1)',
  's-icon-neutral-disabled': 'rgba(186, 190, 195, 1)',
  's-icon-neutral-hovered': 'rgba(26, 28, 29, 1)',
  's-icon-neutral-pressed': 'rgba(68, 71, 74, 1)',
  's-icon-neutral-subdued': 'rgba(140, 145, 150, 1)',

  's-input-dark': 'rgba(15, 15, 15, 1)',
  's-input-light': 'rgba(249, 249, 249, 1)',

  's-neutral-dark': 'rgba(45, 55, 72, 1)',
  's-neutral-disabled-dark': 'rgba(241, 242, 243, 1)',
  's-neutral-disabled-light': 'rgba(241, 242, 243, 1)',
  's-neutral-hovered-dark': 'rgba(241, 242, 243, 1)',
  's-neutral-hovered-light': 'rgba(241, 242, 243, 1)',
  's-neutral-light': 'rgba(228, 229, 231, 1)',
  's-neutral-pressed-dark': 'rgba(201, 204, 208, 1)',
  's-neutral-pressed-light': 'rgba(201, 204, 208, 1)',

  's-neutral-subdued-dark': 'rgba(246, 246, 247, 1)',
  's-neutral-subdued-light': 'rgba(246, 246, 247, 1)',

  's-success': 'rgba(174, 233, 209, 1)',
  's-success-disabled': 'rgba(241, 248, 245, 1)',
  's-success-subdued': 'rgba(222, 242, 233, 1)',
  's-success-subdued-hovered': 'rgba(236, 246, 241, 1)',
  's-success-subdued-pressed': 'rgba(226, 241, 234, 1)',

  's-warning': 'rgba(255, 215, 157, 1)',
  's-warning-disabled': 'rgba(255, 245, 234, 1)',
  's-warning-subdued': 'rgba(255, 245, 234, 1)',
  's-warning-subdued-hovered': 'rgba(255, 242, 226, 1)',
  's-warning-subdued-pressed': 'rgba(255, 235, 211, 1)',

  't-base': 'red',
  't-base-dark': '#ffffff',
  't-base-light': 'rgba(32, 34, 35, 1)',
  't-critical': 'rgba(215, 44, 13, 1)',
  't-disabled': 'rgba(140, 145, 150, 1)',
  't-on-critical': 'rgba(0, 0, 0, 1)',
  't-placeholder': 'rgba(109, 113, 117, 1)',
  't-subdued': 'red',
  't-subdued-dark': '#ededed',
  't-subdued-light': 'rgba(109, 113, 117, 1)',

  'theme-primary-b': 'red',
  'theme-primary-b-dark': 'rgba(0, 128, 96, 1)',
  'theme-primary-b-light': 'rgba(0, 128, 96, 1)',
  'theme-primary-s': 'red',
  'theme-primary-s-dark': 'rgba(0, 110, 82, 1)',
  'theme-primary-s-dark-hovered': 'rgba(0, 112, 84, 1)',
  'theme-primary-s-hovered': 'red',
  'theme-primary-s-light': 'rgba(0, 110, 82, 1)',
  'theme-primary-s-light-hovered': 'rgba(0, 128, 96, 1)',
  'theme-primary-t': 'rgba(0, 110, 82, 1)',
};

export const fill = {
  'f-brand-brown': 'red',
  'f-icon-neutral': 'red',
  'f-icon-neutral-disabled': 'red',
  'f-icon-neutral-hovered': 'red',
  'f-icon-neutral-pressed': 'red',
  'f-icon-neutral-subdued': 'red',
  's-brand-brown': 'rgba(88, 55, 38, 1)',
  's-icon-neutral': 'rgba(92, 95, 98, 1)',
  's-icon-neutral-disabled': 'rgba(186, 190, 195, 1)',
  's-icon-neutral-hovered': 'rgba(26, 28, 29, 1)',
  's-icon-neutral-pressed': 'rgba(68, 71, 74, 1)',
  's-icon-neutral-subdued': 'rgba(140, 145, 150, 1)',
  'theme-primary-f': 'rgba(0, 128, 96, 1)',
  'theme-primary-f-hovererd': 'rgba(0, 110, 82, 1)',
};

export const schemes = {
  success: {
    500: 'blue',
  },
};
