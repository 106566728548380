import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface States {
  memberName: string;
  memberUuid: string;
  tabIndex: number;
}

const initialState: States = {
  memberName: '',
  memberUuid: '',
  tabIndex: 0,
};

export const notificationsEditNotificationSlice = createSlice({
  initialState,
  name: 'notificationsEditNotification',
  reducers: {
    setMemberName: (state, { payload }: PayloadAction<string>) => {
      state.memberName = payload;
    },
    setMemberUuid: (state, { payload }: PayloadAction<string>) => {
      state.memberUuid = payload;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default notificationsEditNotificationSlice.reducer;

export const {
  setMemberName: setMemberNameAction,
  setMemberUuid: setMemberUuidAction,
  setTabIndex: setTabIndexAction,
} = notificationsEditNotificationSlice.actions;
