import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  editSubscriptionsEndCursor: string;
  editSubscriptionsOrderBy: OrderBy;
  editSubscriptionsPageNumber: number;
  editSubscriptionsPageSize: number;
  editSubscriptionsRowIndex: number;
  editSubscriptionsSearchTerm: string;
  membershipUuid: string;
}

const initialState: States = {
  editSubscriptionsEndCursor: '',
  editSubscriptionsOrderBy: {
    direction: Direction.Desc,
    field: 'endDate',
  },
  editSubscriptionsPageNumber: 0,
  editSubscriptionsPageSize: 100,
  editSubscriptionsRowIndex: -1,
  editSubscriptionsSearchTerm: '',
  membershipUuid: '',
};

export const membersEditSubscriptionsSlice = createSlice({
  initialState,
  name: 'membersEditSubscriptions',
  reducers: {
    setEditSubscriptionsEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.editSubscriptionsEndCursor = payload;
    },
    setEditSubscriptionsOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.editSubscriptionsOrderBy = payload;
    },
    setEditSubscriptionsPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.editSubscriptionsPageNumber = payload;
    },
    setEditSubscriptionsPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.editSubscriptionsPageSize = payload;
    },
    setEditSubscriptionsRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.editSubscriptionsRowIndex = payload;
    },
    setEditSubscriptionsSearchTerm: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.editSubscriptionsSearchTerm = payload;
    },
    setMembershipUuid: (state, { payload }: PayloadAction<string>) => {
      state.membershipUuid = payload;
    },
  },
});

export default membersEditSubscriptionsSlice.reducer;

export const {
  setEditSubscriptionsEndCursor: setEditSubscriptionsEndCursorAction,
  setEditSubscriptionsPageNumber: setEditSubscriptionsPageNumberAction,
  setEditSubscriptionsPageSize: setEditSubscriptionsPageSizeAction,
  setEditSubscriptionsRowIndex: setEditSubscriptionsRowIndexAction,
  setEditSubscriptionsSearchTerm: setEditSubscriptionsSearchTermAction,
  setMembershipUuid: setMembershipUuidAction,
} = membersEditSubscriptionsSlice.actions;
