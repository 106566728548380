import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SelectedMember = {
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
};

type Mode = 'indoor' | 'field' | 'game' | 'practise';

interface States {
  mode: Mode;
  selectedMember: SelectedMember;
}

const initialState: States = {
  mode: 'indoor',
  selectedMember: {
    memberIndividualId: '',
    memberName: '',
    memberUuid: '',
  },
};

export const bookingsNewBookingSlice = createSlice({
  initialState,
  name: 'bookingsNewBooking',
  reducers: {
    setMode: (state, { payload }: PayloadAction<Mode>) => {
      state.mode = payload;
    },
    setSelectedMember: (state, { payload }: PayloadAction<SelectedMember>) => {
      state.selectedMember = payload;
    },
  },
});

export default bookingsNewBookingSlice.reducer;

export const {
  setMode: setModeAction,
  setSelectedMember: setSelectedMemberAction,
} = bookingsNewBookingSlice.actions;
